<template>
  <vx-card :title="title">
    <div class="vx-row mb-6">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
      >Back
      </vs-button>
    </div>

    <div class="flex md:flex-row">
      <div class="w-full pr-6 md:basis-1/2">

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>MSS ID</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="code" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Warehouse</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="warehouse" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Warehouse Area</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="warehouseArea" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Document Date</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="documentDate" disabled readonly></vs-input>
          </div>
        </div>

        <div v-if="status === 3 && remark === 'Executed'" class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Posting Date</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="postingDate" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>COA</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="COA" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Cost Center</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="costCenter" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Reason</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="reason" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>External Code</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="externalCode" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Note</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-textarea class="w-full bg-grey-light" :value="note" disabled readonly/>
          </div>
        </div>
      </div>

      <div class="w-full md:basis-1/2">
        <div class="mb-6 vx-row">
          <vs-divider style="width: 50%; margin-left: 10%">
            List Attachment
          </vs-divider>
          <div
            class="vx-row mb-3 mt-6 w-4/4"
            style="width: 50%; margin-left: 10%"
          >
            <table class="vs-table vs-table--tbody-table">
              <template>
                <tr
                  class="tr-values vs-table--tr tr-table-state-null selected"
                  v-bind:key="tr.NameFile"
                  v-for="tr in fileAttachment"
                >
                  <td class="td vs-table--td">{{ tr.FileName }}</td>
                  <td class="td vs-table--td">
                    <vx-tooltip text="Show" v-if="tr.Path != ''">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-eye"
                        @click.stop="handleShowAttachment(tr.FileUrl)"
                      />
                    </vx-tooltip>
                  </td>

                </tr>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>

    <br>
    <vs-table
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
    >
      <template slot="thead">
        <vs-th>SKU</vs-th>
        <vs-th>Batch</vs-th>
        <vs-th>Expired Date</vs-th>
        <vs-th>HU</vs-th>
        <vs-th>System Qty</vs-th>
        <vs-th>Settlement Qty</vs-th>
        <vs-th>Settlement Value</vs-th>
        <vs-th>Charge to</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="`${tr.SkuCode} - ${tr.ItemName}`">
            {{ `${tr.SkuCode} - ${tr.ItemName}` }}
          </vs-td>
          <vs-td :data="tr.BatchExternal">
            {{ tr.BatchExternal }}
          </vs-td>
          <vs-td :data="formatDate(tr.ExpiredDate)">
            {{ formatDate(tr.ExpiredDate) }}
          </vs-td>
          <vs-td :data="tr.Unit">
            {{ tr.Unit }}
          </vs-td>
          <vs-td :data="tr.SystemQty">
            {{ tr.SystemQty }}
          </vs-td>
          <vs-td>
            <div v-for="charge in tr.charges" :key="charge.id">
              <vs-input
                class="w-full"
                :value="charge.SettlementQty"
                disabled readonly></vs-input>
            </div>
          </vs-td>
          <vs-td>
            <div v-for="charge in tr.charges" :key="charge.id">
              <vs-input
                class="w-full"
                :value="priceFormat(charge.SettlementValue)"
                disabled readonly></vs-input>
            </div>
          </vs-td>
          <vs-td>
            <div v-for="charge in tr.charges" :key="charge.id">
              <vs-input
                class="w-full"
                :value="charge.ChargeTo"
                disabled readonly></vs-input>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </vx-card>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      title: "View Details",
      id: this.$route.query.id ? parseInt(this.$route.query.id) : null,
      code: null,
      warehouse: null,
      warehouseArea: null,
      documentDate: null,
      postingDate: null,
      COA: null,
      costCenter: null,
      reason: null,
      externalCode: null,
      note: null,
      fileAttachment: null,
      lines: [],
      table: this.tableDefaultState(),
      status: null,
      remark: null,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        transactionDate: null,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleShowAttachment(file) {
      window.open(file, "_blank");
    },
    handleBack() {
      this.$vs.loading();
      if(this.$route.query.is_approval === 1) {
        this.$router.push({
          name: "missing-stock-settlement-approval",
          query: { tab: this.$route.query.tab },
        });
      }else {
        this.$router.push({
          name: "missing-stock-settlement",
          query: { tab: this.$route.query.tab },
        });
      }
      this.$vs.loading.close();
    },
    getData() {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/missing-stock-settlement/" + this.id).then((resp) => {
        if (resp.code === 200) {
          this.code = resp.data.mss.Code;
          this.warehouse = resp.data.mss.WarehouseCode + " - " + resp.data.mss.WarehouseName;
          this.warehouseArea = resp.data.mss.WarehouseAreaCode + " - " + resp.data.mss.WarehouseAreaName;
          this.documentDate = this.formatDate(resp.data.mss.DocumentDate);
          this.postingDate = this.formatDate(resp.data.mss.PostingDate);
          this.COA = resp.data.mss.ChartOfAccountsCode + " - " + resp.data.mss.ChartOfAccountsName;
          this.costCenter = resp.data.mss.CostCenterCode + " - " + resp.data.mss.CostCenterName;
          this.reason = resp.data.mss.ReasonsCode + " - " + resp.data.mss.Reasons;
          this.externalCode = resp.data.mss.ExternalCode;
          this.note = resp.data.mss.Note;
          this.fileAttachment = resp.data.mss_files;
          this.table.data = resp.data.mss_line;
          this.table.total = resp.data.mss_line.length;
          this.status = resp.data.mss.Status;
          this.remark = resp.data.mss.Remark;
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check"
          });
        }
        this.$vs.loading.close();
      });
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      return moment(date).format('DD MMM YYYY');
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    formatMoney(val, decimal, decSep, thouSep) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(decimal).replace(thouSep, decSep);
      var valsplit = val.split(".")
      return valsplit[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+"."+valsplit[1];
    },
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
}
</script>
